<template>
  <div>
    <div class="my-6 text-startr">
      <h3 class="font-bold">Lista de {{ route.name }}</h3>
      <span class="text-sm text-gray-500"
        >Aqui você pode observar todos os itens relacionados aos usuários como
        suas respectivas ações.
      </span>
    </div>
    <div class="flex mb-6 justify-end">
      <div class="mr-3">
        <router-link to="/vinculos">
          <Button color="primary" text="Vínculos de usuários"></Button>
        </router-link>
      </div>
      <div class="ml-3">
        <router-link to="/usuarios/criar">
          <Button color="primary" text="Criar novo usuário"></Button>
        </router-link>
      </div>
    </div>
    <div class="card">
      <DataTable
        :headers="headers"
        :data="data"
        :options="options"
        :exports="true"
        color="primary"
      />
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE } from "../../services/api";
import { useRoute } from "vue-router";

export default {
  name: "Usuarios",
  components: {
    DataTable,
    Button,
  },
  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  setup() {
    const headers = [
      { key: "Id", value: "id" },
      { key: "Usuario", value: "usuario" },
      { key: "Ações", value: "" },
    ];

    const route = useRoute();
    const modal = inject("modal");
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);

    onMounted(async () => {
      try {
        loader.open();
        data.value = await GET("usuarios");

        loader.close();
      } catch (e) {
        loader.close();
        alert.open("Erro", e, "danger");
      }
    });

    /*const deleteItem = (user) => {
      loader.open();

      const deleteFunction = async function() {
        try {
          loader.open();

          await DELETE(`usuarios/${user.id}`);
          data.value = data.value.filter((item) => item.id != user.id);
          loader.close();
          alert.open("Sucesso!", `Usuário deletado com sucesso!`, "success");
        } catch (e) {
          loader.close();
          alert.open("Erro", e, "danger");
        }
      };
      modal.open(
        "Atenção",
        "Deseja realmente excluir esse usuário?",
        "warning",
        "Sim",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };*/

    const options = [
      { icon: "pen", path: "/usuarios/editar", title: "Editar usuário" },
      // { icon: "trash", action: (param) => deleteItem(param), title: "Excluir usuário" },
    ];

    return { headers, data, options, route };
  },
};
</script>

<style>

</style>
